import anime from 'animejs'
import splt from 'spltjs'
import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.menu()
    }

    static sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        $(window).on('load scroll', function () {
            const offset = 200 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()
            let hasBanner = false

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                hasBanner = true
            }

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $(NavbarEnum.LOGO)
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            setTimeout(() => {
                logo.attr('src', logoClassic)
            }, 250) // delay for logo animation
        }
    }

    static burger() {
        //Open/Close menu
        $(NavbarEnum.BURGER_MENU_TRIGGER).on('click', () => {
            $(NavbarEnum.BODY).toggleClass('menu-open')
            $(NavbarEnum.BURGER_MENU_LABEL).toggleClass('is-active')

            if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                NavbarComponent.changeLogoColor('banner')
            } else {
                if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                    NavbarComponent.changeLogoColor('banner')
                } else {
                    NavbarComponent.changeLogoColor('classic')
                }
            }
        })
    }

    static menu() {
        const spltItem = '.menu__quote__excerpt'
        splt({ target: spltItem })
        $(spltItem).addClass('is-splitted')

        if (window.innerWidth > 650) {
            const index = $(NavbarEnum.MENU_ITEM_TRIGGER).first().index()
            $(`[data-customer-menu-trigger="${index}"], [data-menu-index="${index}"]`).addClass(
                'is-active',
            )
        } else {
            $(NavbarEnum.MENU_BACK_TRIGGER).on('click', (e) => {
                $('[data-customer-menu-trigger], [data-menu-index]').removeClass('is-active')
                $(NavbarEnum.BODY).removeClass('submenu-open')
            })
        }

        $(NavbarEnum.MENU_ITEM_TRIGGER).on('click', function (e) {
            e.preventDefault()
            const index = $(this).index()
            $('[data-customer-menu-trigger], [data-menu-index]').removeClass('is-active')
            $(`[data-customer-menu-trigger="${index}"], [data-menu-index="${index}"]`).addClass(
                'is-active',
            )
            anime({
                targets: '.menu__quote.is-active .char',
                opacity: [0, 1],
                translateY: [10, 0],
                delay: anime.stagger(15),
            })
            if (window.innerWidth <= 650) {
                $(NavbarEnum.BODY).addClass('submenu-open')
            }
        })
    }
}
