export default class NavbarEnum {
    static ECOMODE = '[data-id="eco-bar"]'

    // Burger
    static BODY = 'body'
    static NAVBAR = '[data-customer-id="navbar"]'
    static LOGO = '[data-customer-id="navbar-logo"]'

    // Burger
    static BURGER_MENU_TRIGGER = '[data-customer-trigger="burger-menu-toggle"]'
    static BURGER_MENU_LABEL = '[data-customer-id="menu-label"]'

    // Menu
    static MENU_ITEM_TRIGGER = '[data-customer-menu-trigger]'
    static MENU_BACK_TRIGGER = '[data-customer-trigger="menu-back"]'
}
